<template>
  <!-- 提示弹出窗，获取多少学时等提示 -->
  <div class="period-popup" v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="() => {}"
      width="380px"
    >
      <div
        v-if="dialog"
        class="dialog-delete"
        @click="
          () => {
            dialogVisible = false;
          }
        "
      >
        <i class="el-icon-close"></i>
      </div>
      <div class="popup-title" v-if="headline">{{ headline }}</div>
      <div class="flex conet j-c title">{{ title }}</div>
      <div class="type" v-if="type == 'ONSITE_TEACHING'">
        <div class="f-bg">
          <div id="qrcode" ref="qrCodeUrl"></div>
        </div>
      </div>
      <div
        :class="['btn', 'flex', 'conet', title.length < 12 ? 'top' : '']"
        @click="beforClose"
      >
        {{ btnText }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
  name: 'PeriodPopup',
  props: {
    title: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    link: {
      type: Number
    },
    btnText: {
      type: String,
      default: '好的'
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  components: {
    QRCode
  },
  watch: {
    dialogVisible(vol) {
      if (vol) {
        if (this.type == 'ONSITE_TEACHING') {
          this.$nextTick(() => {
            this.qrcode();
          });
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    beforClose() {
      this.dialogVisible = false;
      this.$emit('periodPopup', { code: 200, msg: '完成情况' });
    },
    qrcode() {
      const _this = this;
      const text = process.env.VUE_APP_EWM_URL + _this.link;
      const qrcode = new QRCode(_this.$refs.qrCodeUrl, {
        width: 124,
        height: 124, // 高度
        text: text, // 二维码内容
        render: 'canvas', // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        background: '#f0f', // 背景色
        foreground: '#ff0' // 前景色
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~./popup";
/deep/.el-dialog__headerbtn {
  display: none;
}
.dialog-delete {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  .el-icon-close {
    font-size: 20px;
  }
  .el-icon-close:hover {
    color: #00bd92;
  }
}
</style>