/**
 * 统一存储，方便管理调试
  */
// 保存日期
export function setTrainStationOrCity(data) {
  localStorage.setItem('H5_DATA', data)
}
// 存储登录状态
export function setConsumType(data) {
  localStorage.setItem('H5_LOGIN_TYPE', data)
}
// 存储用户信息到本地
export function setUserData(data) {
  localStorage.setItem('H5_USER_TOKEN', data)
}
// 图片头像
export function setUserwebImgUrl(data) {
  localStorage.setItem('H5_USER_INFO_IMG', data)
}
// 名称
export function setUserwebUsername(data) {
  localStorage.setItem('H5_USER_INFO_NSME', data)
}
// id
export function setUserwebUserId(data) {
  localStorage.setItem('H5_USER_INFO_USER_ID', data)
}
// 学校
export function setUserwebsSchool(data) {
  localStorage.setItem('H5_USER_INFO_USER_SCHOOL', data)
}
// 手机号
export function setUserwebPhone(data) {
  localStorage.setItem('H5_USER_INFO_USER_PHONE', data)
}