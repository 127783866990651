import phonePopup from '@/components/trainPopup/phone-popup';
import vue from 'vue';
import pluginsFactory from './pluginsFactory';
export default (options = {}, Vue) => {
  return new Promise(resolve => {
    if (!Vue) Vue = vue
    const mount = pluginsFactory(Vue, phonePopup)
    let vm = mount()
    // 组件传过来的参数
    Object.keys(options).forEach(k => {
      vm[k] = options[k]
    })
    vm.dialogVisible = true
    vm.$on('phonePopup', (data) => {
      vm = null
      resolve(data)
    })
  })
}