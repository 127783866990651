import Vuex from 'vuex';
import Vue from 'vue';
import * as lt from '../utils/localStorageUtils';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userData: {},         // 用户数据
    type: '',             // 是否登录状态
    routerAction: '',     // 路由前进后退
    requestCancelArr: [], // 请求列表
    token: '',
    webImgUrl: '',        // 头像
    username: '',         // 名称
    userId: '',           // id
    total: '',            // 数量
    school: '',           // 学校
    checked: false,       // 登录勾选
    userPhone: '',
    objectOk: {},
    isGenerationApply: false
  },
  mutations: {
    // 登录状态
    setConsumType(state, type) {
      if (type) {
        lt.setConsumType(type);
      }
      state.type = type;
    },
    setUserData(state, token) {
      lt.setUserData(token);
      state.token = token;
    },
    // 数量
    setTotal(state, total) {
      state.total = total;
    },
    setRequestCancelArr(state, arr) {
      state.requestCancelArr = arr;
    },
    // 路由
    setRouterAction(state, action) {
      state.routerAction = action;
    },
    // 图片
    setUserwebImgUrl(state, webImgUrl) {
      if (webImgUrl) {
        lt.setUserwebImgUrl(webImgUrl)
      }
      state.webImgUrl = webImgUrl
    },
    // 名称
    setUserwebUsername(state, username) {
      if (username) {
        lt.setUserwebUsername(username);
      }
      state.username = username;
    },
    // id
    setUserwebUserId(state, userId) {
      if (userId) {
        lt.setUserwebUserId(userId);
      }
      state.userId = userId;
    },
    // 学校
    setUserwebsSchool(state, school) {
      if (school) {
        lt.setUserwebsSchool(school);
      }
      state.school = school;
    },
    // 手机
    setUserwebPhone(state, userPhone) {
      if (userPhone) {
        lt.setUserwebPhone(userPhone);
      }
      state.userPhone = userPhone;
    },
    // 登录选中
    isChecked(state, checked) {
      state.checked = !checked
    },
    // 缓存封装
    setModuleState: (state, value) => {
      Object.keys(value).forEach(key => {
        state[key] = value[key]
      })
    }
  },
  actions: {},
  modules: {
    // ...modules
  },
  getters: {
    // 登录状态
    consumType(state) {
      return state.type || localStorage.getItem('H5_TRIP_TYPE');
    },
    // 请求头
    token: (state) => (token) => {
      return state.token || localStorage.getItem('H5_USER_TOKEN');
    },
    // 头像
    webImgUrls(state) {
      return state.webImgUrl || localStorage.getItem('H5_USER_INFO_IMG');
    },
    // 名称
    username(state) {
      return state.username || localStorage.getItem('H5_USER_INFO_NSME');
    },
    // id
    userId(state) {
      return state.userId || localStorage.getItem('H5_USER_INFO_USER_ID');
    },
    school(state) {
      return state.school || localStorage.getItem('H5_USER_INFO_USER_SCHOOL');
    },
    // 数量
    total(state) {
      return state.total;
    },
    // 选中
    checked(state) {
      return state.checked;
    },
    userPhone(state) {
      return state.userPhone || localStorage.getItem('H5_USER_INFO_USER_PHONE');
    }
  }
})