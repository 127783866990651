import { ajax } from './config'
/**
 * 获取验证码
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const loginQrCode = (params) => ajax('/v1/webLogin/yieldLoginUrl', 'GET', { params })
/**
 * 扫码监听回调
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const qrCodeStr = (params) => ajax(`/v1/webLogin/scanPolling`, 'GET', { params })

/**
 * 退出登录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const logout = (params) => ajax(`/v1/logout`, 'GET', { params, useToken: true })
/**
 * 首页信息接口
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const miniHomePageActivitys = (params) => ajax('/v1/homePage/web/activitys', 'GET', { params })
/**
 * 用户信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const userInfo = (params) => ajax('/v1/userInfo', 'GET', { params, useToken: true })
/**
 * 个人中心信息 学时 课程
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const getCreditStatistics = (params) => ajax(`/v1/personalCenter/getCreditStatistics/${params.year}`, 'GET', { useToken: true })
/**
 * 资讯 动态 通知（详情）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const informationDetailInformationId = (params) => ajax(`/v1/mini/information/detail/${params.informationId}`, 'GET', { params, useToken: true })
/**
 * 登录后点赞 取消
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const informationLikeInformationId = (params) => ajax(`/v1/mini/information/like/${params.informationId}`, 'PUT', { useToken: true })
/**
 * 登录后收藏 取消
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const informationStoreInformationId = (params) => ajax(`/v1/mini/information/store/${params.informationId}`, 'PUT', { useToken: true })
/**
 * 资讯更多 type = 1 type = 2
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const informationMore = (params) => ajax('/v1/mini/information', 'GET', { params })
/**
 * 搜索活动（课程）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageSearch = (params) => ajax(`/v1/homePage/miniSearch?${params.all}`, 'GET', { })
export const ssssss = () => '/v1/homePage/miniSearch';
/**
 * 精品课（课程）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageRecorded = (params) => ajax(`/v1/homePage/recorded?${params.all}`, 'GET', { })
/**
 * 公开课（课程）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageLiveBoradcast = (params) => ajax(`/v1/homePage/liveBoradcast?${params.all}`, 'GET', { })
/**
 * 面授课（课程）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageOnsiteTeaching = (params) => ajax(`/v1/homePage/onsiteTeaching?${params.all}`, 'GET', { })
/**
 * 活动（课程）详情
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageActivityDetail = (params) => ajax(`/v1/homePage/activityDetail/${params.activityId}`, 'GET', { useToken: true })
/**
 * 活动（课程）目录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageActivityDetailDirecory = (params) => ajax(`/v1/homePage/activityDetail/direcory/${params.activityId}`, 'GET', { useToken: true })
/**
 * 活动（课程）详情 总评分
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageCourseDetailAvgScore = (params) => ajax(`/v1/homePage/courseDetail/avgScore/${params.activityId}`, 'GET', {})
/**
 * 登录后活动收藏 取消
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activityDetailCollect = (params) => ajax(`/v1/mini/activityDetail/collect/${params.activityId}`, 'PUT', { useToken: true })
/**
 * 登录后活动点赞 取消
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activityDetailLike = (params) => ajax(`/v1/mini/activityDetail/like/${params.activityId}`, 'PUT', { useToken: true })
/**
 * 视频播放开始时间 是否可以拖动
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const homePageCourseDetailActivityCourseDetail = (params) => ajax(`/v1/homePage/courseDetail/getActivityCourseDetails/${params.activityCourseId}`, 'GET', { useToken: true })
/** POST
 * 视频播放 30s轮询请求提交时间
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const userWatchInfoSendTime = (params) => ajax('/v1/userWatchInfo/sendTime', 'POST', { params, useToken: true })
/**
 * 获取默认信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherInfoDefault = (params) => ajax('/v1/mini/courseTeacherInfo/default', 'GET', { params, useToken: true });
/**
 * 我要报名
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activityDetailCourseTeacher = (params) => ajax('/v1/mini/activityDetail/courseTeacher', 'PUT', { params, useToken: true });
/**
 * 取消报名
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const myCourseCancelRegister = (params) => ajax(`/v1/myCourse/cancelRegister/${params.activityId}`, 'PUT', { useToken: true });
/**
 * 视频看完后设置进度条可以拖动
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const userWatchInfoPutActivityCourse = (params) => ajax(`/v1/userWatchInfo/putActivityCourse/${params.activityCourseId}`, 'PUT', { useToken: true });
/**
 * 获取用户协议，隐私政策
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const adminProtocolCurrent = (params) => ajax('/v1/admin/protocol/current', 'GET', { params });
/**
 * 管理员聊天记录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const chatRecordAdminNotice = (params) => ajax(`/v1/chatRecord/adminNotice/${params.roomId}/${params.pageNum}/${params.pageSize}`, 'GET', { useToken: true });
/**
 * 聊天记录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const chatRecordRoomId = (params) => ajax(`/v1/chatRecord/${params.roomId}/${params.pageNum}/${params.pageSize}`, 'GET', { useToken: true });
/**
 * 查询自定义报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherInfoRegisterDeploy = (params) => ajax('/v1/mini/courseTeacherInfo/register/deploy', 'GET', { params, useToken: true });
/**
 * 检查该时间段内是否存在报名（直播）
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activityDetailCheckActivityTime = (params) => ajax('/v1/mini/activityDetail/checkActivityTime', 'GET', { params, useToken: true });
/**
 * 直播获取学时
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const userWatchInfoLiveStreamingSendTime = (params) => ajax(`/v1/userWatchInfo/liveStreamingSendTime/${params.activityCourseId}`, 'POST', { useToken: true })
/**
 * 查询自定义报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activityCourseCourseWare = () => '/v1/activityCourse/courseWare';
/**
 * 检查用户是否关注公众号
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const haveOpenId = (params) => ajax('/v1/haveOpenId', 'GET', { params, useToken: true });

/**
 * 我的课程-->我的课件
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const myCourseWare = (params) => ajax('/v1/myCourse/myCourseWare', 'GET', { params, useToken: true });

/** POST
 * 视频播放 30s轮询请求提交时间
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseWareDownload = (params) => ajax(`/v1/homePage/courseWareDownload`, 'POST', { params, useToken: true })

/**
 * 查询自定义报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const substituteRegistrationTemplate = () => '/v1/mini/activityDetail/substituteRegistrationTemplate';

/**
 * 上传代报名列表
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherList = (params) => ajax(`/v1/mini/activityDetail/courseTeacherList/${params.activityId}`, 'GET', { useToken: true });

/** POST
 * 上传代报名
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeachesr = () => `/v1/mini/activityDetail/courseTeacher`;

/**
 * 文件中具体的代报名用户列表
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const agentRegistList = (params) => ajax(`/v1/mini/activityDetail/agentRegistList/${params.activityId}/${params.fileId}/${params.pageSize}/${params.pageNum}`, 'GET', { useToken: true });

// 获取聊天内存记录
export const getChatCacheRecord = (roomId) => ajax(`/v1/chatRecord/realTime/cache/${roomId}`, 'GET', { useToken: true });
// 获取聊天数据库记录
export const getChatDbRecord = (params) => ajax(
  '/v1/chatRecord/realTime/db',
  'GET',
  { params, useToken: true },
);