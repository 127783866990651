import { ajax } from './config';
/**
 * 我的课程当月全部课程
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterTimetable = (params) => ajax('/v1/personalCenter/getTimetable', 'GET', { params, useToken: true })
/**
 * 我的课程当天课程
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterTimetableCourse = (params) => ajax('/v1/personalCenter/getTimetableActivity', 'GET', { params, useToken: true })
/**
 * 我的收藏
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterMyCollect = (params) => ajax(`/v1/personalCenter/getMyCollect`, 'GET', { params, useToken: true })
/**
 * 我的学时
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterMyCredit = (params) => ajax('/v1/personalCenter/getMyCredit', 'GET', { params, useToken: true })
/**
 * 我的个人信息获取
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterMyPersonalDetails = (params) => ajax('/v1/personalCenter/getMyPersonalDetails', 'GET', { params, useToken: true })
/**
 * 获取省
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const locationProvince = (params) => ajax('/v1/location/province', 'GET', { params, useToken: true })
/**
 * 获取市
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const locationCity = (params) => ajax('/v1/location/city', 'GET', { params, useToken: true })
/**
 * 获取区/县
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const locationArea = (params) => ajax('/v1/location/area', 'GET', { params, useToken: true })
/**
 * 获取区/县 下的学校
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const areaSchool = (params) => ajax('/v1/school', 'GET', { params, useToken: true })
/**
 * 获取学科学段数据字典
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const dictDatamanyDictData = (params) => ajax('/v1/admin/dictData/manyDictData', 'POST', { params })
/**
 * 修改我的个人信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterupdateMyPersonalDetails = (params) => ajax('/v1/personalCenter/updateMyPersonalDetails', 'PUT', { params, useToken: true })
/**
 * 用户报名信息-->查看用户所有的报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherInfo = (params) => ajax('/v1/mini/courseTeacherInfo', 'GET', { params, useToken: true })
/**
 * 新增用户报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const postCourseTeacherInfo = (params) => ajax('/v1/mini/courseTeacherInfo', 'POST', { params, useToken: true })
/**
 * 修改用户报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const putCourseTeacherInfo = (params) => ajax('/v1/mini/courseTeacherInfo', 'PUT', { params, useToken: true })
/**
 * 删除用户报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const deleteCourseTeacherInfo = (params) => ajax('/v1/mini/courseTeacherInfo', 'DELETE', { params, useToken: true })
/**
 * 生成验证码
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const verifyPic = (params) => ajax('/v1/verifyPic', 'GET', { params })
/**
 * 校验验证码
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const checkVerify = (params) => ajax('/v1/checkVerify', 'GET', { params, useToken: true })
/**
 * 获取手机验证码
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const smsPhone = (params) => ajax(`/v1/sms/${params.phone}`, 'GET', { params, useToken: true })
/**
 * 设置默认信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherInfoDefault = (params) => ajax(`/v1/mini/courseTeacherInfo/default/${params.courseTeacherInfoId}`, 'PUT', { params, useToken: true })
/**
 * 我的学习记录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterMyPersonalStudyRecord = (params) => ajax('/v1/personalCenter/getMyPersonalStudyRecord', 'GET', { params, useToken: true })
/**
 * 获取手机验证码 更改绑定手机
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterSms = (params) => ajax(`/v1/personalCenter/sms/${params.phone}`, 'GET', { useToken: true })
/**
 * 校验手机验证码
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const personalCenterSmsVerify = (params) => ajax(`/v1/personalCenter/sms/verify/${params.phone}/${params.code}`, 'POST', { params, useToken: true })
/**
 * 获取手机验证码 更改绑定手机
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const getSignIn = (params) => ajax(`/v1/personalCenter/getSignIn`, 'GET', { params, useToken: true })
/**
 * 清空记录
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const delStudyRecord = (params) => ajax('/v1/personalCenter/deleteStudyRecord', 'DELETE', { params, useToken: true })

/**
 * 用户报名信息-->新增 无验证码的情况
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const courseTeacherInfoNocode = (params) => ajax('/v1/mini/courseTeacherInfo/nocode', 'POST', { params, useToken: true })
/**
 * 用户报名信息-->修改 无验证码的情况
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const putCourseTeacherInfoNocode = (params) => ajax('/v1/mini/courseTeacherInfo/nocode', 'PUT', { params, useToken: true })

/**
 * 绑定用户手机号到微信
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const bindUserPhoneToWechat = (params) => ajax('/v1/bindUserPhoneToWechat', 'POST', { params, useToken: true })