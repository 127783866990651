// 校验手机号码
export function checkPhone(phone) {
  return /^1[3456789]\d{9}$/.test(phone)
}
// 校验邮箱
export function checkEmail(email) {
  return /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)
}
// 校验密码
export function checkPassword(password) {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/.test(password)
}
// 校验姓名 最多30字符 汉字占2字符
export const nameReg = (val) => {
  let leng = 0
  const cnReg = /^[\u4E00-\u9FA5]{1}$/
  Array.prototype.slice.call(val).forEach(v => {
    leng += cnReg.test(v) ? 2 : 1
  })
  const regName = /^[\u4E00-\u9FA5A-Za-z/]{2,30}$/
  return val !== '' && leng <= 30 && regName.test(val)
}
// 电话校验
export function checkCompanyPhone(companyPhone) {
  const res = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  return res.test(companyPhone)
}
// 姓名校验
export function checkName(companyPhone) {
  // const res = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、0-9A-Za-z]/im
  const res = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、0-9]/im
  return res.test(companyPhone)
}
// 表情校验
export function checkExpression(companyPhone) {
  const res = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
  return res.test(companyPhone)
}
/**
 * 获取时间戳的每个对象
 * @param ms 时间戳
 * @returns {year: x, month: x, day: x, hour: x,minute: x, second: x,}
 */
function getDateObj(ms) {
  if (!ms) {
    return null
  }
  const msInt = window.parseInt(ms)
  const $date = new Date(msInt)
  const year = $date.getFullYear()
  const month = $date.getMonth() + 1
  const day = $date.getDate()
  const hour = $date.getHours()
  const minute = $date.getMinutes()
  const second = $date.getSeconds()
  return {
    year, month, day, hour, minute, second
  }
}
/*
如果数字前面不是0的话，在前面补0
 */
function prefixZero(num) {
  return num >= 10 ? num : `0${num}`
}
/**
 * 根据时间戳和格式获取时间字符串
 * @param {Number} ms 时间戳
 * @param {String} formatter 转换格式
 * @returns {String}
 * 例如 formatter = '{year}:{month}:{day} {hour}:{minute}:{second}' 返回'xxxx:xx:xx xx:xx:xx'
 */
export function timeFormatter(ms, formatter) {
  if (!ms) return null
  const pat = /{year}|{month}|{day}|{hour}|{minute}|{second}/g
  const $date = getDateObj(ms)
  $date.month = prefixZero($date.month)
  $date.day = prefixZero($date.day)
  $date.hour = prefixZero($date.hour)
  $date.minute = prefixZero($date.minute)
  $date.second = prefixZero($date.second)
  const timeStr = formatter.replace(pat, (word) => {
    const key = word.replace(/{|}/g, '')
    return $date[key]
  })
  return timeStr
}
// 查找数组中最小值
export function mathMin(arrs) {
  var min = arrs[0].courseStartTime;
  for (let i = 1, ilen = arrs.length; i < ilen; i += 1) {
    if (arrs[i].courseStartTime < min) {
      min = arrs[i].courseStartTime;
    }
  }
  return min;
}
// 在数组中查找最大值
export function mathMax(arrs) {
  var max = arrs[0].courseEndTime;
  for (let i = 1, ilen = arrs.length; i < ilen; i++) {
    if (arrs[i].courseEndTime > max) {
      max = arrs[i].courseEndTime;
    }
  }
  return max;
}
export function routerPush(t, path, query = {}) {
  const _this = t
  _this.$router.push({
    path: path,
    query: query
  })
}
export function routerReplace(t, path, query = {}) {
  const _this = t
  _this.$router.replace({
    path: path,
    query: query
  })
}
// 判断对象是否为空 true 为空
export function isEmpty(obj) {
  for (const i in Object.keys(obj)) {
    return false // 进入循环即不为空
  }
  return true
}

// 删除数组里的