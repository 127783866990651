<template>
  <!-- 《 登录，退出，收藏 》弹出窗 -->
  <div class="popup-l" v-show="currentShow">
    <el-dialog
      :visible.sync="currentShow"
      :before-close="() => {}"
      width="380px"
    >
      <div class="flex conet j-c title">{{ title }}</div>
      <div class="popup-btn flex conet">
        <div class="p-btn flex conet j-c" @click="leftBtnIncident">
          {{ leftBtn }}
        </div>
        <div class="p-btn flex conet j-c m-lfet" @click="rightBtnIncident">
          {{ rightBtn }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'trainPopup',
  data() {
    return {
      currentShow: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '确认取消收藏此课程？'
    },
    leftBtn: {
      type: String,
      default: '否'
    },
    rightBtn: {
      type: String,
      default: '是'
    }
  },
  watch: {
    show(nV) {
      if (nV !== this.currentShow) {
        this.currentShow = nV;
      }
    },
    currentShow(nv) {
      nv !== this.show && this.$emit('input', nv);
    }
  },
  methods: {
    leftBtnIncident() {
      this.show = false;
      this.$emit('leftBtnIncident', {});
    },
    rightBtnIncident() {
      this.$emit('selectCityOrStation', { code: 200, mgs: '获取成功' });
      this.show = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.popup-l {
  box-sizing: border-box;
  height: auto;
  padding-bottom: 20px;
  .title {
    .fontSize(@TEXT-SIZE-18);
    text-align: center;
    line-height: 30px;
  }
  .popup-btn {
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }
  .p-btn {
    width: 100px;
    height: 40px;
    border: 1px solid @TEXT-COLOR-E6E6E6;
    border-radius: 20px;
    color: @TEXT-COLOR-888888;
    .fontSize(@TEXT-SIZE-16);
    cursor: pointer;
  }
  .p-btn:hover {
    background: @TEXT-BG-E6E6E6;
  }
  .m-lfet {
    margin-left: 30px;
    background: @TEXT-BG-00BD92;
    color: @TEXT-COLOR-FFFFFF;
  }
  .m-lfet:hover {
    background: @TEXT-BG-00CC9D;
  }
  /deep/.el-dialog {
    border-radius: 10px;
  }
  /deep/.el-dialog__body {
    padding: 40px 30px;
    height: auto;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    overflow: hidden;
  }
  /deep/.el-dialog__header {
    display: none;
  }
}
</style>