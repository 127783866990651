const SEARCH_KEY = '_search_'
const SEARCH_MAX_LENGTH = 9
function insertArray(arr, val, compare, maxlen) {
  const index = arr.findIndex(compare)
  if (index === 0) {
    return
  }
  if (index > 0) {
    arr.splice(index, 1)
  }
  arr.unshift(val);
  if (maxlen && arr.length > maxlen) {
    arr.pop();
  }
}
import storage from 'good-storage';
export function saveSearch(query) {
  const searches = storage.get(SEARCH_KEY, []);
  insertArray(searches, query, (item) => {
    return item === query
  }, SEARCH_MAX_LENGTH)
  storage.set(SEARCH_KEY, searches)
  return searches
}