import { Message } from 'element-ui';
const Utils = {
  // 时间年月日
  getLocalTime(nS, dt = 'date') {
    const date = new Date(nS);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
    const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    if (dt === 'date') {
      return Y + M + D;
    } else if (dt === 'time') {
      return h + m + s;
    } else if (dt === 'datetime') {
      return Y + M + D + h + m + s;
    } else if (dt == 'month') {
      return Y + MM;
    }
    return Y + M + D;
  },
  msgWarning(msg, time = 1000) {
    Message({
      duration: time,
      type: 'warning',
      message: msg
    })
  },
  msgSuccess(msg, time = 1000) {
    Message({
      duration: time,
      type: 'success',
      message: msg
    })
  }
};
export default Utils;
