import router from '../router';
import store from '../store';
// 插件工厂函数
export default (Vue, component) => {
  const Profile = Vue.extend(component)
  const div = document.createElement('div')
  document.body.appendChild(div)
  const vm = new Profile()
  vm.$store = store
  vm.router = router
  return function () {
    vm.$mount(div)
    return vm
  }
}