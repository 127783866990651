import trainStationComponent from '@/components/trainPopup/Index'
import vue from 'vue'
import pluginsFactory from './pluginsFactory'
export default (options = {}, Vue) => {
  return new Promise(resolve => {
    if (!Vue) Vue = vue
    const mount = pluginsFactory(Vue, trainStationComponent)
    let vm = mount()
    // 组件传过来的参数
    Object.keys(options).forEach(k => {
      vm[k] = options[k]
    })
    vm.show = true
    vm.$on('selectCityOrStation', (data) => {
      vm = null
      resolve(data)
    })
  })
}