import trainPopup from './trainPopup';
import qrCode from './qr-code';
import periodPopup from './period-popup';
import subscribePopup from './subscribe-popup'
import phonePopup from './phonePopup'
const plugins = {
  trainPopup: (Vue) => (options) => trainPopup(options, Vue),
  qrCode: (Vue) => (options) => qrCode(options, Vue),
  periodPopup: (Vue) => (options) => periodPopup(options, Vue),
  subscribePopup: (Vue) => (options) => subscribePopup(options, Vue),
  phonePopup: (Vue) => (options) => phonePopup(options, Vue)
}
const mixinPlugins = []
export default {
  install(Vue) {
    mixinPlugins.forEach(fun => fun(Vue))
    Object.keys(plugins).forEach(key => {
      Vue.prototype[`$${key}`] = plugins[key](Vue)
    })
  }
}
