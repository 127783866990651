<template>
  <!-- 绑定微信手机号 -->
  <div class="popup-phone" v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="() => {}"
      width="400px"
    >
      <div
        class="dialog-delete"
        @click="
          () => {
            dialogVisible = false;
          }
        "
      >
        <i class="el-icon-close"></i>
      </div>
      <template>
        <div class="title">请输入微信绑定的手机号</div>
        <input
          type="text"
          maxlength="11"
          v-model="phone"
          oninput="value=value.replace(/[^0-9]/g,'')"
          class="phone-input"
          placeholder="请输入微信绑定的手机号"
        />
        <div class="flex bor">
          <input
            type="text"
            v-model="codeImg"
            maxlength="4"
            oninput="value=value.replace(/[^0-9A-Za-z]/g,'')"
            class="phone-input"
            placeholder="输入图形验证码"
          />
          <img
            :src="picImg"
            alt=""
            class="codeImgShow"
            @click.stop="getVerifyPic"
          />
        </div>
        <div class="flex conet top">
          <input
            class="flex-input"
            type="text"
            maxlength="6"
            v-model="code"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="输入验证码"
          />
          <div class="verification" @click.stop="verificationCodeIncident">
            {{ codeText }}
          </div>
        </div>
        <div
          class="dialog-btn flex conet j-c"
          @click="informationRegistrationIncident"
        >
          确认绑定
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { checkPhone } from '@/utils/verify';
import { countDown } from '@/utils/commonUtils';
import {
  verifyPic,
  checkVerify,
  personalCenterSms,
  bindUserPhoneToWechat
} from '@/api/personal';
import Utils from '@/utils';
import { userInfo } from '@/api/api';
import store from '@/store';
export default {
  name: 'PopupPhone',
  data() {
    return {
      phone: '',
      codeText: '发送验证码',
      getOnceCodeFlag: true,
      codeImg: '',
      codekey: '',
      picImg: '',
      getCodeFlag: false,
      code: '',
      dialogVisible: false
    };
  },
  watch: {
    codeImg(val) {
      if (val.length > 3) {
        checkVerify({
          key: this.codekey,
          code: val
        }).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.getCodeFlag = true;
            } else {
              Utils.msgWarning('图形验证码校验失败');
              this.getVerifyPic();
              this.codeImg = '';
            }
          }
        });
      }
    }
  },
  created() {
    this.getVerifyPic();
  },
  methods: {
    informationRegistrationIncident() {
      if (!checkPhone(this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (!this.getCodeFlag) {
        Utils.msgWarning('图形验证码不正确');
        return;
      }
      if (this.code == '') {
        Utils.msgWarning('验证码不能为空');
        return;
      }
      const obj = {
        phone: this.phone,
        smsCode: this.code
      };
      bindUserPhoneToWechat(obj).then((res) => {
        if (res.code == 200) {
          Utils.msgSuccess('绑定成功');
          setTimeout(() => {
            userInfo({}).then((res) => {
              if (res.code == 200) {
                const { phone, userId, username, headImage, school } = res.data;
                store.commit('setUserwebImgUrl', headImage);
                store.commit('setUserwebUsername', username);
                store.commit('setUserwebUserId', userId);
                store.commit('setUserwebsSchool', school);
                store.commit('setUserwebPhone', phone);
                this.dialogVisible = false;
              }
            });
          }, 1000);
        }
      });
    },
    getVerifyPic() {
      verifyPic({}).then((res) => {
        if (res.code == 200) {
          this.picImg = 'data:image/png;base64,' + res.data.pic;
          this.codekey = res.data.key;
        }
      });
    },
    verificationCodeIncident() {
      const _this = this;
      if (!checkPhone(_this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (!_this.getCodeFlag) {
        Utils.msgWarning('图形验证码不正确');
        return;
      }
      if (_this.getOnceCodeFlag) {
        _this.getOnceCodeFlag = false;
        personalCenterSms({
          phone: _this.phone
        }).then((res) => {
          if (res.code == 200) {
            Utils.msgSuccess('发送验证码成功，请注意查看手机短信');
          }
        });
        countDown(60, (time) => {
          _this.codeText = `${time}s`;
          if (time <= 0) {
            _this.getOnceCodeFlag = true;
            _this.codeText = '发送验证码';
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.popup-phone {
  box-sizing: border-box;
  height: 324px;
  .title {
    text-align: center;
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    margin-bottom: 40px;
  }
  .phone-input {
    padding: 0 15px;
    width: 100%;
    height: 38px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .phone-input:focus {
    border: 1px solid #00bd92;
  }
  .bor {
    margin-top: 20px;
    .codeImgShow {
      width: 120px;
      height: 38px;
      cursor: pointer;
    }
  }
  .top {
    margin-top: 20px;
    .flex-input {
      padding: 0 15px;
      width: 200px;
      height: 38px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .flex-input:focus {
      border: 1px solid #00bd92;
    }
  }
  .dialog-btn {
    margin: 40px auto 0 auto;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF;
    background: @TEXT-BG-00BD92;
  }
  .verification {
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-00BD92;
    margin-left: 10px;
    cursor: pointer;
  }
  /deep/.el-dialog {
    border-radius: 10px;
  }
  /deep/.el-dialog__body {
    padding: 10px 60px;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    height: 340px;
    overflow: hidden;
  }
  /deep/.el-dialog__wrapper {
    z-index: 99999;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
    color: @TEXT-COLOR-00BD92;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close {
    color: @TEXT-COLOR-363636;
  }
  /deep/.el-dialog__header {
    font-size: 20px;
    font-weight: bold;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;
  }
}
/deep/.el-dialog__headerbtn {
  display: none;
}
.dialog-delete {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  .el-icon-close {
    font-size: 20px;
  }
  .el-icon-close:hover {
    color: #00bd92;
  }
}
</style>