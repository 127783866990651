<template>
  <div id="app">
    <yan-header v-if="show"></yan-header>
    <router-view :style="{ minHeight: wrapperHeight - 230 - 80 + 'px' }" />
    <yan-footer v-if="show"></yan-footer>
  </div>
</template>
<script>
import YanHeader from '@/components/header/index';
import YanFooter from '@/components/footer/index';
export default {
  name: 'App',
  components: {
    YanHeader,
    YanFooter
  },
  data() {
    return {
      wrapperHeight: 0,
      show: true
    };
  },
  watch: {
    $route(val, oldVal) {
      if (val.name === 'webView') {
        this.show = false;
      } else {
        this.show = true;
      }
    }
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  mounted() {
    this.wrapperHeight = document.documentElement.clientHeight;
    window.onresize = () => {
      this.wrapperHeight = document.documentElement.clientHeight;
    };
  },
  updated() {
    window.scroll(0, 0);
  }
};
</script>
<style lang="less">
@import "assets/style/reset.css";
@import "assets/style/personage-nav.less";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
</style>

