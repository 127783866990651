import SubscribePopup from '@/components/trainPopup/subscribe-popup';
import vue from 'vue';
import pluginsFactory from './pluginsFactory';
export default (options = {}, Vue) => {
  return new Promise(resolve => {
    if (!Vue) Vue = vue
    const mount = pluginsFactory(Vue, SubscribePopup)
    let vm = mount()
    Object.keys(options).forEach(k => {
      vm[k] = options[k]
    })
    vm.dialogVisible = true
    vm.$on('subscribePopup', (data) => {
      vm = null
      resolve(data)
    })
  })
}