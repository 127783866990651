import 'babel-polyfill'; // 支持es6 IE9
import Vue from 'vue';
import App from './App';
import elementPlguins from './plugins/element';
import router from './router';
import store from './store';
import plugins from './plugins';
Vue.use(plugins);
Vue.use(elementPlguins);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
