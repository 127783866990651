<template>
  <!-- 关注公众号 -->
  <div class="period-popup" v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="
        () => {
          dialogVisible = true;
        }
      "
      width="420px"
    >
      <div
        class="dialog-delete"
        @click="
          () => {
            dialogVisible = false;
          }
        "
      >
        <i class="el-icon-close"></i>
      </div>
      <div class="popup-title">开启课程提醒</div>
      <div class="type">
        <div class="f-bg">
          <img src="~@/assets/images/home/QR-code.png" alt="" />
        </div>
      </div>
      <div class="flex conet j-c title">关注公众号</div>
      <div class="flex conet j-c title">{{ title }}</div>
      <div
        :class="['btn', 'flex', 'conet', title.length < 12 ? 'top' : '']"
        @click="beforClose"
      >
        好的
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'SubscribePopup',
  data() {
    return {
      dialogVisible: false,
      title: '课程即将开始时将微信信息通知您'
    };
  },
  methods: {
    beforClose() {
      this.dialogVisible = false;
      this.$emit('subscribePopup', { code: 200, msg: '完成情况' });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~./popup";
/deep/.el-dialog__headerbtn {
  display: none;
}
.f-bg {
  width: 220px !important;
  height: 220px !important;
  border: none !important;
  img {
    width: 100% !important;
    height: 220px !important;
  }
}
.title {
  font-size: 13px !important;
  padding: 0 !important;
}
.dialog-delete {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  .el-icon-close {
    font-size: 20px;
  }
  .el-icon-close:hover {
    color: #00bd92;
  }
}
</style>