import QrCode from '@/components/trainPopup/qr-code'
import vue from 'vue'
import pluginsFactory from './pluginsFactory'
import router from '@/router';
export default (options = {}, Vue) => {
  return new Promise(resolve => {
    if (!Vue) Vue = vue
    const mount = pluginsFactory(Vue, QrCode)
    let vm = mount()
    Object.keys(options).forEach(k => {
      vm[k] = options[k]
    })
    vm.dialogVisible = true
    vm.$on('qrCode', (data) => {
      vm = null
      resolve(data)
    })
    vm.$on('agreementIncident', (data) => {
      vm = null
      router.push({
        path: '/agreement',
        query: {
          type: data,
          path: router.history.current.fullPath
        }
      });
    })
  })
}