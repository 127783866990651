import axios, { CancelToken } from 'axios';
import Vue from 'vue';
import store from '@/store';
// import router from '../router';
import Utils from '@/utils';
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,   // url = base url + request url
  timeout: 5000      // request timeout
});
instance.interceptors.request.use((config) => {
  config.cancelToken = new CancelToken(c => {
    const { state: { requestCancelArr }} = store
    store.commit('setRequestCancelArr', [...requestCancelArr, { key: config.url, cancelToken: c }])
  })
  return config
}, (error) => {
  return Promise.reject(error);
});
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  if (response.status === 200) {    // 响应成功后
    if (response.headers['token']) {  // 获取响应头里面的数据，**Authorization根据你响应头里面的数据获取，并不是唯一值**
        store.commit('setUserData', response.headers['token']);
    }
  }
  if (response.data.code === 4001) {   // 认证失败
      noTokenHandle();
  }
  if (response.data.code !== 200 && response.data.code !== 4001 && response.data.code !== 4110 && response.data.code !== 4014) {
    if (response.data.msg != '未使用过二维码') {
      Utils.msgWarning(response.data.msg || '未知错误');
    }
  }
  return response
}, function (error) {
  try {
    const { status } = error.response
    const data = error.response.data
    switch (status) {
      case 4110:
        // autoLogin(store.state.userData.access_token)
        break
      default:
        Utils.msgWarning(data.msg || '系统走神了,错误码:' + status);
    }
  } catch (e) {
    // Empty
  }
  return Promise.reject(error);
})
function getAuthorization(useToken, Authorization) {
  if (!useToken) {
    return ''
  }
  const token = store.getters.token();
  if (token) {
    return 'hys' + token;
  } else if (localStorage.getItem('H5_USER_TOKEN')) {
    return 'hys' + localStorage.getItem('H5_USER_TOKEN');
  } else {
    return ''
  }
}
/**
 通用方法
 useToken 标识该接口是否使用token
 noLogin 标识该接口在没有token的情况是否需要登录， 不传此参数默认在没有token的情况下进行登录
 */
export const ajax = function(url, method, { params, header = {}, otherConfig = {}, useToken } = {}) {
  return new Promise((resolve, reject) => {
    try {
      const Authorization = getAuthorization(useToken, header.Authorization);
      const headers = { ...header, Authorization };
      const configs = { timeout: 30000, url, method, headers, ...otherConfig };
      if (method === 'GET' || method === 'DELETE') {
          configs.params = params;
      } else {
          configs.data = params;
      }
      instance(configs).then(res => {
        return resolve(res.data);
      }).catch(err => {
        return reject(err);
      })
    } catch (e) {
      reject(e)
    }
  })
}
// 没有token统一处理
function noTokenHandle() {
    localStorage.clear();
    const instance = new Vue();
    instance.$qrCode().then(res => {
      location.reload();
    })
}