// 倒计时
export function countDown(time, callBack) {
  if (time <= 0) {
    // 返回空函数，防止调用报错
    return () => { }
  }
  let timeId = 0, hideTime = 0
  // 第一次调用
  callBack(time)
  function visibility() {
    if (document.visibilityState === 'hidden') {
      // 隐藏了记住当前时间
      hideTime = new Date().getTime()
    } else {
      const offsetTime = Math.floor((new Date().getTime() - hideTime) / 1000)
      time = time - offsetTime
    }
  }
  document.addEventListener('visibilitychange', visibility)
  function timeCont() {
    if (time <= 0) {
      document.removeEventListener('visibilitychange', visibility)
      callBack(time)
      return
    }
    timeId = setTimeout(() => {
      time--
      callBack(time)
      timeCont()
    }, 1000)
  }
  timeCont()
  return function () {
    // 主动调用可直接清除监听和倒计时
    clearTimeout(timeId)
    document.removeEventListener('visibilitychange', visibility)
  }
}
// 延时调用
export const delayedCall = (time = 500) => {
  let id
  return (func) => {
    if (id) {
      clearTimeout(id)
    }
    id = setTimeout(() => {
      func()
    }, time)
  }
}