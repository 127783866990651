export function pupopStatus(Status) {
  switch (Status) {
    case 1:
      return ['确认退出本次登录？', '请先登录平台？', '确认清空历史记录吗？', '确认取消报名了吗？']
    case 2:
      return ['取消']
    case 3:
      return ['确认']
    case 4:
      return ['您已报名过该时段的其他课程，确认继续报名当前课程吗？']
    case 5:
      return ['已将该课程添加进【我的学习】，请合理安排时间进行学习。', '已将该课程添加进【我的学习】。请准时参加直播活动，如中途进入或退出直播间，可能对课程完成情况产生影响。', '扫码进入小程序，可查看入场门票。']
    case 6:
      return ['因您的不当言行，您已被禁言', '系统进行全部禁言']
  }
  return ['', '']
}