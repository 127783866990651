import {
  Button,
  Row,
  Col,
  Radio,
  Pagination,
  Dialog,
  Tabs,
  TabPane,
  Select,
  Option,
  Table,
  TableColumn,
  Calendar,
  Loading,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Upload
} from 'element-ui'
const pluginsArr = [
  Button,
  Row,
  Col,
  Radio,
  Pagination,
  Dialog,
  Tabs,
  TabPane,
  Select,
  Option,
  Table,
  TableColumn,
  Calendar,
  Loading,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Upload
]
export default {
  install(vue) {
    pluginsArr.forEach(p => vue.use(p))
  }
}